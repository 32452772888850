import React from 'react';
import styled from 'styled-components';
import logo from '../assets/espa-logo.jpg';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <Wrapper>
      <div className='main-footer'>
        <h5>
          &copy; {new Date().getFullYear()}
          <span> MODAMOU </span>
        </h5>
        <h5>All rights reserved</h5>
      </div>

      <div className='secondary-footer'>
        <Link to={require('../assets/espa-poster.pdf')} target='blank'>
          <img src={logo} alt='espa-logo' />
        </Link>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.footer`
  .main-footer {
    height: 5rem;
    justify-content: center;
    align-items: center;
    background: var(--clr-black);
    text-align: center;
    display: flex;
    flex-direction: column;
  }
  .secondary-footer {
    height: 5rem;
    justify-content: center;
    align-items: center;
    background: var(--clr-black);
    text-align: center;
    display: flex;
    flex-direction: column;
    padding: 3.5rem;
  }

  img {
    width: 100%;
    height: 6rem;
  }
  span {
    color: var(--clr-primary-5);
  }
  h5 {
    color: var(--clr-white);
    margin: 0.1rem;

    font-weight: 400;
    text-transform: none;
    line-height: 1.25;
  }
  @media (min-width: 776px) {
    flex-direction: row;
  }

  @media only screen and (max-width: 600px) {
    img {
      width: 100%;
      height: 3.5rem;
    }
  }
`;

export default Footer;
