import {
  CREATE_ORDER_BEGIN,
  CREATE_ORDER_SUCCESS,
  CREATE_ORDER_ERROR,
  GET_MY_ORDERS_BEGIN,
  GET_MY_ORDERS_SUCCESS,
  GET_MY_ORDERS_ERROR,
} from '../actions';

const order_reducer = (state, action) => {
  if (action.type === CREATE_ORDER_BEGIN) {
    return {
      ...state,
      order_create_loading: true,
      order_create_error: false,
    };
  }
  if (action.type === CREATE_ORDER_SUCCESS) {
    return {
      ...state,
      order_create_loading: false,
      order_created: true,
      order: action.payload,
    };
  }
  if (action.type === CREATE_ORDER_ERROR) {
    return {
      ...state,
      order_create_loading: false,
      order_create_error: action.payload,
    };
  }

  if (action.type === GET_MY_ORDERS_BEGIN) {
    return {
      ...state,
      my_orders_loading: true,
      my_orders_error: false,
    };
  }
  if (action.type === GET_MY_ORDERS_SUCCESS) {
    return {
      ...state,
      my_orders_loading: false,
      orders: action.payload,
    };
  }
  if (action.type === GET_MY_ORDERS_ERROR) {
    return {
      ...state,
      my_orders_loading: false,
      my_orders_error: action.payload,
    };
  }

  throw new Error(`No Matching "${action.type}" - action type`);
};

export default order_reducer;
