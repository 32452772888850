import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
const PageHero = ({ title, product }) => {
  return (
    <Wrapper>
      <div className='section-center'>
        <h4>
          <Link to='/'>Αρχική </Link>
          {product && <Link to='/products'>/ Προϊόντα</Link>}/{' '}
          {title === 'products'
            ? 'Προϊόντα'
            : title === 'cart'
            ? 'Καλάθι'
            : title === 'about'
            ? 'Σχετικά'
            : title === 'placeOrder'
            ? 'Ταμείο'
            : title}
        </h4>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  background: var(--clr-grey-10);
  width: 100%;
  min-height: 5vh;
  display: flex;
  align-items: center;

  h4 {
    font-size: 1rem;
  }

  color: var(--clr-primary-1);
  a {
    color: var(--clr-grey-5);
    padding: 0.5rem;
    transition: var(--transition);
  }
  a:hover {
    color: var(--clr-primary-1);
  }
`;

export default PageHero;
