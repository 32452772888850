import React from 'react';

import Error from '../components/Error';
import Loading from '../components/Loading';
import { useOrderContext } from '../context/order_context';

const OrderHistoryPage = () => {
  const { orders, my_orders_loading, my_orders_error } = useOrderContext();

  if (my_orders_loading) {
    return <Loading />;
  }
  if (my_orders_error) {
    return <Error />;
  }
  if (orders.length < 1) {
    return <h5 style={{ textTransform: 'none' }}>Δεν Υπάρχουν Παραγγελίες</h5>;
  }
};

export default OrderHistoryPage;
