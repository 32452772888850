import React, { useContext, useEffect, useReducer } from 'react';
import reducer from '../reducers/order_reducer';
import { toast } from 'react-toastify';
import axios from 'axios';
import { order_url } from '../utils/constants';
import {
  CREATE_ORDER_BEGIN,
  CREATE_ORDER_SUCCESS,
  CREATE_ORDER_ERROR,
  GET_MY_ORDERS_BEGIN,
  GET_MY_ORDERS_SUCCESS,
  GET_MY_ORDERS_ERROR,
} from '../actions';

const initialState = {
  order_create_loading: false,
  order_create_error: false,
  order_created: false,
  order: null,
  my_orders_loading: false,
  my_orders_error: false,
  orders: [],
};

const OrderContext = React.createContext();
export const OrderProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const createOrder =
    (
      cart,
      fullName,
      telephone,
      address,
      city,
      zipCode,
      shipping_fee,
      payOnDelivery_fee
    ) =>
    async (event) => {
      dispatch({ type: CREATE_ORDER_BEGIN });
      event.preventDefault();
      const data = {
        tax: 24,
        fullName,
        shippingFee: shipping_fee,
        items: cart,
        payOnDelivery_fee,
        telephone,
        address,
        city,
        zipCode,
      };
      try {
        const response = await axios.post(`${order_url}`, data, {
          withCredentials: true,
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
          },
        });
        const order = response.data.order;
        toast.success('Η Παραγγελία σας Ολοκληρώθηκε Επιτυχώς!');
        dispatch({ type: CREATE_ORDER_SUCCESS, payload: order });

        // if (order) {
        //   return res.redirect('/home');
        // }
      } catch (error) {
        //toast.error(error.response.data.msg);
        dispatch({
          type: CREATE_ORDER_ERROR,
          payload: error.response.data.msg,
        });
      }
    };

  const fetchMyOrders = async () => {
    dispatch({ type: GET_MY_ORDERS_BEGIN });
    try {
      const response = await axios.get(`${order_url}showAllMyOrders`, {
        withCredentials: true,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        },
      });
      const myOrders = response.data.orders;
      console.log(myOrders);
      dispatch({ type: GET_MY_ORDERS_SUCCESS, payload: myOrders });
    } catch (error) {
      dispatch({ type: GET_MY_ORDERS_ERROR });
    }
  };

  useEffect(() => {
    fetchMyOrders();
  }, []);

  return (
    <OrderContext.Provider value={{ ...state, createOrder }}>
      {children}
    </OrderContext.Provider>
  );
};

export const useOrderContext = () => {
  return useContext(OrderContext);
};
