import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { useCartContext } from '../context/cart_context';
import { useOrderContext } from '../context/order_context';

const OrderAddressForm = () => {
  const { cart, shipping_fee, payOnDelivery_fee, clearCart } = useCartContext();
  const { createOrder, order_created, order, order_create_error } =
    useOrderContext();

  const [fullName, setfullName] = useState('');
  const [telephone, setTelephone] = useState('');
  const [address, setΑddress] = useState('');
  const [city, setCity] = useState('');
  const [zipCode, setZipCode] = useState('');

  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    if (id === 'name') {
      setfullName(value);
    }
    if (id === 'telephone') {
      setTelephone(value);
    }
    if (id === 'address') {
      setΑddress(value);
    }
    if (id === 'city') {
      setCity(value);
    }
    if (id === 'zipCode') {
      setZipCode(value);
    }
  };

  useEffect(() => {
    if (order_create_error) {
      toast.error(order_create_error);
    }
    // eslint-disable-next-line
  }, [order_create_error]);

  useEffect(() => {
    if (order) {
      setTimeout(() => {
        navigate('/orderHistory');
        clearCart();
        window.location.reload();
      }, '2000');
    }
    // eslint-disable-next-line
  }, [order]);

  return (
    <Wrapper>
      <div className='container'>
        <div className='brand-title'>Διευθηνση Αποστολής</div>
        <form
          method='POST'
          onSubmit={createOrder(
            cart,
            fullName,
            telephone,
            address,
            city,
            zipCode,
            shipping_fee,
            payOnDelivery_fee
          )}
        >
          <div className='inputs'>
            <label htmlFor='username'>Ονοματεπώνυμο</label>
            <input
              required={true}
              type='text'
              value={fullName}
              onChange={(e) => handleInputChange(e)}
              id='name'
              placeholder='Ονοματεπώνυμο'
            />
            <label htmlFor='telephone'>Τηλέφωνο</label>
            <input
              required={true}
              type='telephone'
              value={telephone}
              onChange={(e) => handleInputChange(e)}
              id='telephone'
              placeholder='Τηλέφωνο'
            />
            <label htmlFor='address'>Διεύθηνση</label>
            <input
              required={true}
              type='text'
              value={address}
              onChange={(e) => handleInputChange(e)}
              id='address'
              placeholder='Διεύθηνση'
            />

            <label htmlFor='city'>Πόλη</label>
            <input
              required={true}
              type='text'
              value={city}
              onChange={(e) => handleInputChange(e)}
              id='city'
              placeholder='Πόλη'
            />

            <label htmlFor='zipCode'>Τ.Κ.</label>
            <input
              required={true}
              type='text'
              value={zipCode}
              onChange={(e) => handleInputChange(e)}
              id='zipCode'
              placeholder='Τ.Κ.'
            />

            <button type='submit' disabled={false}>
              Υποβολή Παραγγελίας
            </button>
          </div>
        </form>
      </div>
    </Wrapper>
  );
};
const Wrapper = styled.section`
  margin-top: 1rem;
  input {
    caret-color: red;
  }

  .error {
    color: red;
  }

  .container {
    width: 500px;
    height: 800px;
    border-radius: 20px;
    padding: 40px;
    background: #ecf0f3;
  }

  .brand-logo {
    height: 100px;
    width: 100px;
    margin: auto;
    border-radius: 50%;
    box-sizing: border-box;
    box-shadow: 7px 7px 10px #cbced1, -7px -7px 10px white;
  }

  .brand-title {
    margin-top: 10px;
    font-weight: 900;
    font-size: 1.8rem;
    color: #555150;
    letter-spacing: 1px;
  }

  .inputs {
    text-align: left;
    margin-top: 30px;
  }

  label,
  input,
  button {
    display: block;
    width: 100%;
    padding: 0;
    border: none;
    outline: none;
    box-sizing: border-box;
  }

  label {
    margin-bottom: 4px;
  }

  label:nth-of-type(2) {
    margin-top: 12px;
  }

  input::placeholder {
    color: gray;
  }

  input {
    background: #ecf0f3;
    padding: 10px;
    padding-left: 20px;
    height: 50px;
    font-size: 14px;
    border-radius: 50px;
    box-shadow: inset 6px 6px 6px #cbced1, inset -6px -6px 6px white;
  }

  button {
    color: white;
    margin-top: 20px;
    background: #555150;
    height: 40px;
    border-radius: 20px;
    cursor: pointer;
    font-weight: 900;
    box-shadow: 6px 6px 6px #cbced1, -6px -6px 6px white;
    transition: 0.5s;
  }

  button:hover {
    box-shadow: none;
  }

  p {
    padding-top: 0.5rem;
  }

  /* a {
    position: absolute;
    font-size: 8px;
    bottom: 4px;
    right: 4px;
    text-decoration: none;
    color: black;
    background: yellow;
    border-radius: 10px;
    padding: 2px;
  } */

  h1 {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

export default OrderAddressForm;
