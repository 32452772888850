import React from 'react';
import { GiDiamondHard, GiStabbedNote } from 'react-icons/gi';
import { FaShippingFast } from 'react-icons/fa';

export const links = [
  {
    id: 1,
    text: 'αρχική',
    url: '/',
  },
  {
    id: 2,
    text: 'προϊόντα',
    url: '/products',
  },
  {
    id: 3,
    text: 'εταιρεία',
    url: '/about',
  },
];

export const services = [
  {
    id: 1,
    icon: <FaShippingFast />,
    title: 'express αποστολές',
    text: 'Το MODAMOU εγγυάται ότι οι αποστολές θα γίνονται αυθημερόν. Έτσι παραλαμβάνεται τις παραγγελίες στο πι και φι!',
  },
  {
    id: 2,
    icon: <GiDiamondHard />,
    title: 'vision',
    text: 'Ακολουθούμε την μόδα για να σας παρέχουμε τα καλύτερα προϊόντα',
  },
  {
    id: 3,
    icon: <GiStabbedNote />,
    title: 'history',
    text: 'Η ιστορία μας ξεκινά τώρα',
  },
];

export const server_url = 'https://modamou-shop-api.onrender.com/';

export const products_url =
  'https://modamou-shop-api.onrender.com/api/v1/products';

export const single_product_url = `https://modamou-shop-api.onrender.com/api/v1/products/`;

export const auth_url = `https://modamou-shop-api.onrender.com/api/v1/auth/`;

export const order_url = `https://modamou-shop-api.onrender.com/api/v1/orders/`;

// export const server_url = 'http://localhost:5000/';

// export const products_url = 'http://localhost:5000/api/v1/products';

// export const single_product_url = `http://localhost:5000/api/v1/products/`;

// export const auth_url = `http://localhost:5000/api/v1/auth/`;

// export const order_url = `http://localhost:5000/api/v1/orders/`;
