import React from 'react';
import styled from 'styled-components';
import { BsFillTelephoneFill } from 'react-icons/bs';
import { FaWhatsapp } from 'react-icons/fa';

const Header = () => {
  return (
    <HeaderContainer>
      <div className='nav-center'>
        <div className='nav-header'>
          <ul className='nav-links'>
            <li>
              <a href='tel:+306944984062'>
                <p>
                  <BsFillTelephoneFill />
                  <span> 694 498 4062</span>
                </p>
              </a>
            </li>

            <li>
              <a href='https://wa.me/+306944984062'>
                <p>
                  <FaWhatsapp />
                  <span> 694 498 4062</span>
                </p>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </HeaderContainer>
  );
};

const HeaderContainer = styled.header`
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--clr-grey-9);
  padding:2rem 1rem ;

 

  .nav-center {
    width: 90vw;
    margin: 0 auto;
    max-width: var(--max-width);
  }
  .nav-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
      width: 150px;
      height: 85px;
      margin-left: -15px;
    }
  }
  .nav-toggle {
    background: transparent;
    border: transparent;
    color: var(--clr-primary-5);
    cursor: pointer;
    svg {
      font-size: 2rem;
    }
  }
  .nav-links {
    display: none;
  }
  .cart-btn-wrapper {
    display: none;
  }
  @media (min-width: 992px) {
    /* .nav-toggle {
      display: none; */
    }
    .nav-center {
      display: grid;
      grid-template-columns: auto 1fr auto;
      align-items: center;
    }
    .nav-links {
      display: flex;
      justify-content: center;
      li {
        margin: 0 0.5rem;
      }
      a {
        color: var(--clr-grey-3);
        font-size: 1rem;
        text-transform: capitalize;
        letter-spacing: var(--spacing);
        padding: 0.5rem;
      }
    }
    .cart-btn-wrapper {
      display: grid;
    }
  }
`;

export default Header;
