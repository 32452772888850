import { Link, useNavigate } from 'react-router-dom';
import { useUserContext } from '../context/user_context';
import { toast } from 'react-toastify';
import PasswordStrengthBar from 'react-password-strength-bar';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import logo from '../assets/logo-r.jpg';

const RegisterPage = () => {
  const { register, user_register_error } = useUserContext();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [comparePasswords, setComparePasswords] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);

  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    if (id === 'name') {
      setName(value);
    }
    if (id === 'email') {
      setEmail(value);
    }
    if (id === 'password') {
      setPassword(value);
    }
    if (id === 'confirmPassword') {
      setConfirmPassword(value);
    }
  };

  useEffect(() => {
    if (localStorage.getItem('user')) {
      navigate('/');
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (password !== confirmPassword) {
      setComparePasswords('Οι Κωδικοί δεν ταιριάζουν');
      setIsDisabled(true);
    } else {
      setComparePasswords('');
      setIsDisabled(false);
    }
    // eslint-disable-next-line
  }, [confirmPassword]);

  useEffect(() => {
    if (user_register_error) {
      toast.error(user_register_error);
    }
    // eslint-disable-next-line
  }, [user_register_error]);

  return (
    <Wrapper>
      <div className='container'>
        <div className='brand-logo'>
          <img src={logo} alt='' className='brand-logo' />
        </div>
        <div className='brand-title'>Εγγραφή</div>
        <form method='POST' onSubmit={register(name, email, password)}>
          <div className='inputs'>
            <label htmlFor='username'>Ονοματεπώνυμο</label>
            <input
              required='true'
              type='text'
              value={name}
              onChange={(e) => handleInputChange(e)}
              id='name'
              placeholder='Ονοματεπώνυμο'
            />
            <label htmlFor='email'>Email</label>
            <input
              required='true'
              type='email'
              value={email}
              onChange={(e) => handleInputChange(e)}
              id='email'
              placeholder='Email'
            />
            <label htmlFor='password'>Κωδικός</label>
            <input
              required='true'
              type='password'
              minLength={6}
              value={password}
              onChange={(e) => handleInputChange(e)}
              id='password'
              placeholder='Κωδικός'
            />
            <PasswordStrengthBar
              minLength={6}
              password={password}
              shortScoreWord='Πολύ Μικρός'
              scoreWords={[
                'Αδύναμος',
                'Μέτριος',
                'Καλός',
                'Δυνατός',
                'Εξαιρετικός',
              ]}
            />
            <label htmlFor='confirmPassword'>Επιβεβαίωση Κωδικού</label>
            <input
              required='true'
              type='password'
              minLength={6}
              value={confirmPassword}
              onChange={(e) => handleInputChange(e)}
              id='confirmPassword'
              placeholder='Επιβεβαίωση Κωδικού'
            />
            <PasswordStrengthBar
              minLength={6}
              password={confirmPassword}
              shortScoreWord='Πολύ Μικρός'
              scoreWords={[
                'Αδύναμος',
                'Μέτριος',
                'Καλός',
                'Δυνατός',
                'Εξαιρετικός',
              ]}
            />
            <span className='error'>{comparePasswords}</span>
            <button type='submit' disabled={isDisabled}>
              Εγγραφή
            </button>
            <p className='text-center'>
              Έχεις ήδη λογαρισαμό;
              <Link to='/login'> Είσοδος</Link>
            </p>
          </div>
        </form>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  margin: 0;
  width: 100vw;
  height: 100vh;
  background: #ecf0f3;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  place-items: center;
  overflow: hidden;
  font-family: poppins;

  input {
    caret-color: red;
  }

  .error {
    color: red;
  }

  .container {
    position: relative;
    width: 350px;
    height: 800px;
    border-radius: 20px;
    padding: 40px;
    box-sizing: border-box;
    background: #ecf0f3;
    box-shadow: 14px 14px 20px #cbced1, -14px -14px 20px white;
  }

  .brand-logo {
    height: 100px;
    width: 100px;
    margin: auto;
    border-radius: 50%;
    box-sizing: border-box;
    box-shadow: 7px 7px 10px #cbced1, -7px -7px 10px white;
  }

  .brand-title {
    margin-top: 10px;
    font-weight: 900;
    font-size: 1.8rem;
    color: #555150;
    letter-spacing: 1px;
  }

  .inputs {
    text-align: left;
    margin-top: 30px;
  }

  label,
  input,
  button {
    display: block;
    width: 100%;
    padding: 0;
    border: none;
    outline: none;
    box-sizing: border-box;
  }

  label {
    margin-bottom: 4px;
  }

  label:nth-of-type(2) {
    margin-top: 12px;
  }

  input::placeholder {
    color: gray;
  }

  input {
    background: #ecf0f3;
    padding: 10px;
    padding-left: 20px;
    height: 50px;
    font-size: 14px;
    border-radius: 50px;
    box-shadow: inset 6px 6px 6px #cbced1, inset -6px -6px 6px white;
  }

  button {
    color: white;
    margin-top: 20px;
    background: #555150;
    height: 40px;
    border-radius: 20px;
    cursor: pointer;
    font-weight: 900;
    box-shadow: 6px 6px 6px #cbced1, -6px -6px 6px white;
    transition: 0.5s;
  }

  button:hover {
    box-shadow: none;
  }

  p {
    padding-top: 0.5rem;
  }

  /* a {
    position: absolute;
    font-size: 8px;
    bottom: 4px;
    right: 4px;
    text-decoration: none;
    color: black;
    background: yellow;
    border-radius: 10px;
    padding: 2px;
  } */

  h1 {
    position: absolute;
    top: 0;
    left: 0;
  }
`;
export default RegisterPage;
