import React, { useContext, useEffect, useState, useReducer } from 'react';
import reducer from '../reducers/user_reducer';
import { toast } from 'react-toastify';
import axios from 'axios';
import { auth_url } from '../utils/constants';
import {
  USER_SIGNIN_BEGIN,
  USER_SIGNIN_SUCCESS,
  USER_SIGNIN_ERROR,
  USER_REGISTER_BEGIN,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_ERROR,
} from '../actions';

const initialState = {
  user_loading: false,
  user_error: false,
  user_register_loading: false,
  user_register_error: false,
  user: null,
};

const UserContext = React.createContext();
export const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const [myUser, setMyUser] = useState(null);

  const signin = (email, password) => async (event) => {
    dispatch({ type: USER_SIGNIN_BEGIN });
    event.preventDefault();
    const data = {
      email,
      password,
    };

    try {
      const response = await axios.post(`${auth_url}login`, data, {
        withCredentials: true,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        },
      });

      localStorage.setItem('user', JSON.stringify(response.data.user));
      const user = response.data.user;
      setMyUser(user);
      toast.success('Συνδεθήκατε Επιτυχώς!');
      dispatch({ type: USER_SIGNIN_SUCCESS, payload: user });
    } catch (error) {
      dispatch({
        type: USER_SIGNIN_ERROR,
        payload: error.response.data.msg,
      });
    }
  };

  const register = (name, email, password) => async (event) => {
    dispatch({ type: USER_REGISTER_BEGIN });
    event.preventDefault();
    const data = {
      name,
      email,
      password,
    };

    try {
      const response = await axios.post(`${auth_url}register`, data);
      const user = response.data.user;
      toast.success('Ο Λογαρισμός σας Δημιουργήθηκε Επιτυχώς!');
      dispatch({ type: USER_REGISTER_SUCCESS, payload: user });
    } catch (error) {
      dispatch({ type: USER_REGISTER_ERROR, payload: error.response.data.msg });
    }
  };

  const logout = async () => {
    try {
      const response = await axios.get(`${auth_url}logout`, {
        withCredentials: true,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        },
      });
      toast.success('Αποσυνδεθήκατε Επιτυχώς!');
      setTimeout(() => {
        window.location.reload();
      }, '2000');
    } catch (error) {
      toast.error(error.response.data.msg);
    }
  };

  useEffect(() => {
    if (localStorage.getItem('user')) {
      setMyUser(localStorage.getItem('user'));
    }
  }, []);

  return (
    <UserContext.Provider
      value={{ ...state, signin, register, myUser, logout }}
    >
      {children}
    </UserContext.Provider>
  );
};
// make sure use
export const useUserContext = () => {
  return useContext(UserContext);
};
