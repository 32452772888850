import { FormInput, SubmitBtn } from '../components';
import { Link, useNavigate } from 'react-router-dom';
import { customFetch } from '../utils/helpers';
import { toast } from 'react-toastify';
import axios from 'axios';
import styled from 'styled-components';
import { useUserContext } from '../context/user_context';
import logo from '../assets/logo-r.jpg';
import { useEffect, useState } from 'react';

const LoginPage = () => {
  const { signin, user_error, user } = useUserContext();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { id, value } = e.target;

    if (id === 'email') {
      setEmail(value);
    }
    if (id === 'password') {
      setPassword(value);
    }
  };
  useEffect(() => {
    if (localStorage.getItem('user')) {
      navigate('/');
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (user_error) {
      toast.error(user_error);
    }
    // eslint-disable-next-line
  }, [user_error]);

  return (
    <Wrapper>
      <div className='container'>
        <div className='brand-logo'>
          <img src={logo} alt='' className='brand-logo' />
        </div>
        <div className='brand-title'>Είσοδος</div>
        <form method='POST' onSubmit={signin(email, password)}>
          <div className='inputs'>
            <label htmlFor='email'>Email</label>
            <input
              type='email'
              value={email}
              onChange={(e) => handleInputChange(e)}
              id='email'
              placeholder='Email'
            />
            <label htmlFor='password'>Κωδικός</label>
            <input
              type='password'
              value={password}
              onChange={(e) => handleInputChange(e)}
              id='password'
              placeholder='Κωδικός'
            />
            <div className='mt-4'>
              <button type='submit'>Είσοδος</button>
            </div>
            <p className='text-center'>
              Δεν έχεις λογαριασμό;
              <Link to='/register'> Εγγραφή</Link>
            </p>
          </div>
        </form>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  margin: 0;
  width: 100vw;
  height: 100vh;
  background: #ecf0f3;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  place-items: center;
  overflow: hidden;
  font-family: poppins;

  input {
    caret-color: red;
  }

  .error {
    color: red;
  }

  .container {
    position: relative;
    width: 350px;
    height: 600px;
    border-radius: 20px;
    padding: 40px;
    box-sizing: border-box;
    background: #ecf0f3;
    box-shadow: 14px 14px 20px #cbced1, -14px -14px 20px white;
  }

  .brand-logo {
    height: 100px;
    width: 100px;
    margin: auto;
    border-radius: 50%;
    box-sizing: border-box;
    box-shadow: 7px 7px 10px #cbced1, -7px -7px 10px white;
  }

  .brand-title {
    margin-top: 10px;
    font-weight: 900;
    font-size: 1.8rem;
    color: #555150;
    letter-spacing: 1px;
  }

  .inputs {
    text-align: left;
    margin-top: 30px;
  }

  label,
  input,
  button {
    display: block;
    width: 100%;
    padding: 0;
    border: none;
    outline: none;
    box-sizing: border-box;
  }

  label {
    margin-bottom: 4px;
  }

  label:nth-of-type(2) {
    margin-top: 12px;
  }

  input::placeholder {
    color: gray;
  }

  input {
    background: #ecf0f3;
    padding: 10px;
    padding-left: 20px;
    height: 50px;
    font-size: 14px;
    border-radius: 50px;
    box-shadow: inset 6px 6px 6px #cbced1, inset -6px -6px 6px white;
  }

  button {
    color: white;
    margin-top: 20px;
    background: #555150;
    height: 40px;
    border-radius: 20px;
    cursor: pointer;
    font-weight: 900;
    box-shadow: 6px 6px 6px #cbced1, -6px -6px 6px white;
    transition: 0.5s;
  }

  button:hover {
    box-shadow: none;
  }

  p {
    padding-top: 0.5rem;
  }

  /* a {
    position: absolute;
    font-size: 8px;
    bottom: 4px;
    right: 4px;
    text-decoration: none;
    color: black;
    background: yellow;
    border-radius: 10px;
    padding: 2px;
  } */

  h1 {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

export default LoginPage;
