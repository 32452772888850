import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import App from './App';

import { ProductsProvider } from './context/products_context';
import { FilterProvider } from './context/filter_context';
import { CartProvider } from './context/cart_context';
import { UserProvider } from './context/user_context';
import { OrderProvider } from './context/order_context';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <UserProvider>
    <OrderProvider>
      <ProductsProvider>
        <FilterProvider>
          <CartProvider>
            <App />
            <ToastContainer position='top-center' />
          </CartProvider>
        </FilterProvider>
      </ProductsProvider>
    </OrderProvider>
  </UserProvider>
);
