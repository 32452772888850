import { Link, useNavigate } from 'react-router-dom';
import { useOrderContext } from '../context/order_context';
import { toast } from 'react-toastify';
import PasswordStrengthBar from 'react-password-strength-bar';
import axios from 'axios';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import logo from '../assets/logo-r.jpg';
import { useCartContext } from '../context/cart_context';
import {
  CartContent,
  OrderAddressForm,
  OrderContent,
  PageHero,
} from '../components';

const PlaceOrderPage = () => {
  const { createOrder } = useOrderContext();
  const { cart } = useCartContext();

  if (cart.length < 1) {
    return (
      <Wrapper className='page-100'>
        <div className='empty'>
          <h2>Δεν υπάρχουν προϊόντα για παραγγελία</h2>
          <Link to='/products' className='btn'>
            προϊοντα
          </Link>
        </div>
      </Wrapper>
    );
  }
  return (
    <main>
      <PageHero title='placeOrder' />
      <Wrapper className='page'>
        <OrderContent></OrderContent>
      </Wrapper>
    </main>
  );
};

const Wrapper = styled.main`
  .empty {
    text-align: center;
    h2 {
      margin-bottom: 1rem;
      text-transform: none;
    }
  }
`;
export default PlaceOrderPage;
