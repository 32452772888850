import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Navbar, Sidebar, Footer } from './components';
import {
  Home,
  SingleProduct,
  Cart,
  Checkout,
  Error,
  About,
  Products,
  Register,
  Login,
  PrivateRoute,
  AuthWrapper,
  PlaceOrder,
  OrderHistory,
} from './pages';
import Header from './components/Header';
function App() {
  return (
    <AuthWrapper>
      <Router>
        <Header />
        <Navbar />
        <Sidebar />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='about' element={<About />} />
          <Route path='cart' element={<Cart />} />
          <Route path='register' element={<Register />} />
          <Route path='placeOrder' element={<PlaceOrder />} />
          <Route path='login' element={<Login />} />
          <Route path='products' element={<Products />} />
          <Route path='orderHistory' element={<OrderHistory />} />
          <Route path='products/:id' element={<SingleProduct />} />
          <Route
            path='checkout'
            element={
              <PrivateRoute>
                <Checkout />
              </PrivateRoute>
            }
          />
          <Route path='*' element={<Error />} />
        </Routes>
        <Footer />
      </Router>
    </AuthWrapper>
  );
}

export default App;
