import React from 'react';
import styled from 'styled-components';
import { useUserContext } from '../context/user_context';

const AuthWrapper = ({ children }) => {
  const { user_loading, user_register_loading } = useUserContext();

  if (user_loading || user_register_loading) {
    return (
      <Wrapper>
        <h1>Περιμένετε....</h1>
      </Wrapper>
    );
  }

  return <>{children}</>;
};

const Wrapper = styled.section`
  min-height: 100vh;
  display: grid;
  place-items: center;
`;

export default AuthWrapper;
